//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  components: {},
  data: function data() {
    return {
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        status: null
      },
      loves: []
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getLoves();
  },
  mounted: function mounted() {},
  methods: {
    handleDelete: function handleDelete(item) {
      var _this = this;

      this.$confirm('确认删除资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this.$http.get(_this.$constant.baseURL + "/family/deleteFamily", {
          id: item.id
        }, true).then(function (res) {
          _this.pagination.current = 1;

          _this.getLoves();

          _this.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    search: function search() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getLoves();
    },
    getLoves: function getLoves() {
      var _this2 = this;

      this.$http.post(this.$constant.baseURL + "/family/listFamily", this.pagination, true).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.loves = res.data.records;
          _this2.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeStatus: function changeStatus(item) {
      var _this3 = this;

      this.$http.get(this.$constant.baseURL + "/family/changeLoveStatus", {
        id: item.id,
        flag: item.status
      }, true).then(function (res) {
        _this3.$message({
          message: "修改成功！",
          type: "success"
        });
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getLoves();
    }
  }
};